import util from '@/services/util'

const validatePhone = (rule, value, callback) => {
  if (!util.validatePhone(value)) {
    callback(new Error("手机号格式错误"));
  } else {
    callback();
  }
};

export {
  validatePhone
}