<template>
  <div class="sg-container">
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span>{{ title }}</span>
        <el-button
          style="float: right; padding: 3px 0"
          type="text"
          @click="create"
          >新建</el-button
        >
      </div>
      <div class="sg-search-box">
        <el-row>
          <el-col :span="6"
            ><el-input
              v-model="searchItem.name"
              placeholder="请输入关键词搜索"
              @blur="search"
            ></el-input
          ></el-col>
        </el-row>
      </div>
      <el-table :data="list" style="width: 100%" stripe>
        <el-table-column
          prop="name"
          label="用户名"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="email"
          label="邮箱"
          align="center"
        ></el-table-column>
        <el-table-column prop="phone" label="手机" align="center">
        </el-table-column>
        <el-table-column prop="created_at" label="创建时间" align="center">
          <template slot-scope="scope">
            <span style="margin-left: 10px">{{
              scope.row.created_at | formatDate
            }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="updated_at" label="更新时间" align="center">
          <template slot-scope="scope">
            <span style="margin-left: 10px">{{
              scope.row.updated_at | formatDate
            }}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <el-button @click="edit(scope.row)" size="mini">编辑</el-button>
            <el-button type="danger" size="mini" @click="deleteRow(scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div class="sg-pagination">
        <el-pagination
          @current-change="pageChange"
          :current-page="current"
          :page-size="pageSize"
          layout="total, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </el-card>
    <el-dialog
      :title="action == 'create' ? '新建' : '编辑'"
      :visible.sync="showDialog"
      width="60%"
      top="5vh"
    >
      <div class="sg-dialog-body">
        <el-row>
          <el-col :sm="{ span: 18, offset: 3 }">
            <el-form
              ref="createEditForm"
              :model="item"
              label-width="80px"
              :rules="rules"
            >
              <el-form-item prop="name" label="用户名">
                <el-input
                  v-model="item.name"
                  placeholder="请输入用户名"
                  class="sg-login-input"
                ></el-input>
              </el-form-item>
              <el-form-item prop="images" label="头像">
                <sg-upload v-model="item.avatar" :multiple="false"></sg-upload>
              </el-form-item>
              <el-form-item prop="email" label="邮箱">
                <el-input
                  v-model="item.email"
                  placeholder="请输入邮箱"
                  class="sg-login-input"
                ></el-input>
              </el-form-item>
              <el-form-item prop="phone" label="手机">
                <el-input
                  v-model="item.phone"
                  placeholder="请输入手机"
                  class="sg-login-input"
                ></el-input>
              </el-form-item>
              <el-form-item prop="password" label="密码">
                <el-input
                  v-model="item.password"
                  :placeholder="
                    action == 'create'
                      ? '请输入密码'
                      : '请输入密码，不修改请留空'
                  "
                  class="sg-login-input"
                  type="password"
                ></el-input>
              </el-form-item>
              <el-form-item prop="password_confirm" label="重复密码">
                <el-input
                  v-model="item.password_confirm"
                  placeholder="请输入重复密码"
                  class="sg-login-input"
                  type="password"
                ></el-input>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" @click="submit">提交</el-button>
              </el-form-item>
            </el-form>
          </el-col>
        </el-row>
      </div>
    </el-dialog>
  </div>
</template>

<script>
// @ is an alias to /src
// import { mapState } from "vuex"
import { validatePhone } from "@/services/validator.js";

export default {
  name: "AdminUser",
  data() {
    var validatePassword = (rule, value, callback) => {
      var len = value.length;
      if (this.action == "create") {
        if (!len) callback(new Error("密码必填"));
        else if (len < 6) callback(new Error("密码最少6位"));
      }
      callback();
    };
    var validateConfirm = (rule, value, callback) => {
      if (value != this.item.password) {
        callback(new Error("两次输入密码不一致"));
      } else {
        callback();
      }
    };
    return {
      title: "管理员",
      list: [],
      total: 0,
      pageSize: 15,
      current: 1,
      showDialog: false,
      pre_uri: "admin-users/",
      // create/update
      action: "create",
      emptyItem: {
        name: "",
        email: "",
        phone: "",
        password: "",
        password_confirm: "",
        // http://app.vuee/storage/uploadhttps://zhengqizhixing.oss-cn-beijing.aliyuncs.com/images/20201225/5fe60225d098b.png
        avatar: "",
      },
      item: {},
      searchItem: {
        name: "",
      },
      rules: {
        name: [{ required: true, message: "请输入用户名", trigger: "blur" }],
        email: [{ required: true, message: "请输入邮箱", trigger: "blur" }],
        phone: [
          { required: true, message: "请输入手机号", trigger: "blur" },
          { validator: validatePhone, trigger: "blur" },
        ],
        password: [{ validator: validatePassword, trigger: "blur" }],
        password_confirm: [{ validator: validateConfirm, trigger: "blur" }],
      },
    };
  },
  computed: {
    totalPages: () => {
      if (this.total) return Math.ceil(this.total / this.pageSize);
      else return 1;
    },
  },
  mounted() {
    this.getList();
  },
  methods: {
    search() {
      this.current = 1;
      this.getList();
    },
    getList() {
      var that = this;
      this.$store.commit("updateLoading", true);
      this.$http
        .post(
          this.pre_uri + "get",
          Object.assign(
            {
              page: this.current,
            },
            this.searchItem
          )
        )
        .then((res) => {
          this.$store.commit("updateLoading", false);
          if (res.code == 0) {
            that.$util.parsePagiList(that, res.data);
          }
        });
    },
    pageChange(current) {
      this.current = current;
      this.getList();
    },
    create() {
      this.item = Object.assign({}, this.emptyItem);
      this.action = "create";
      this.switchShowDialog(true);
    },
    edit(item) {
      this.item = Object.assign({}, item, {
        password: "",
        password_confirm: "",
      });
      this.action = "edit";
      this.switchShowDialog(true);
    },
    deleteRow(row) {
      this.$confirm("确定删除吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$http
            .post(this.pre_uri + "delete", {
              id: row.id,
            })
            .then((res) => {
              if (res.code == 0) {
                this.$message({
                  type: "success",
                  message: "删除成功!",
                });
                this.getList();
              }
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    switchShowDialog(show) {
      this.showDialog = show;
    },
    submit() {
      this.$refs["createEditForm"].validate((valid) => {
        if (valid) {
          this.submitForm();
          return true;
        } else {
          return false;
        }
      });
    },
    submitForm() {
      var url = this.pre_uri + (this.action == "create" ? "store" : "update");
      this.$http.post(url, this.item).then((res) => {
        if (res.code == 0) {
          this.$message({
            message: "操作成功",
            type: "success",
          });
          this.switchShowDialog(false);
          this.getList();
        }
      });
    },
  },
};
</script>
